import React, { Fragment } from "react";
import AboutUsSection from "../components/AboutUsSection";
import HeroSection from "../components/HeroSection";
import MeetOurTeam from "../components/MeetOurTeam";
import ServiceSection from "../components/ServiceSection";

type Props = {};

const Home = (props: Props) => {
  return (
    <Fragment>
      <HeroSection />
      <AboutUsSection />
      <ServiceSection hasTitle={true} />
      <MeetOurTeam />
    </Fragment>
  );
};

export default Home;
