import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import AboutUs from './pages/AboutUs';
import Home from './pages/Home';
import OurTeam from './pages/OurTeam';
import Services from './pages/Services';
import { IAppContext, ITeamMember } from './types';
import { Zhuliang } from './assets/zhuliang.js';
import { Monique } from './assets/monique.js';
import TeamMember from './pages/TeamMember';


export const AppContext = React.createContext<IAppContext | null>(null);

const navigations = [
  { name: "Home", path: "/" },
  { name: "Services", path: "/services" },
  { name: "Our Team", path: "/our-team" },
  { name: "About Us", path: "/about-us" },
];

const zhuliang: ITeamMember = { 
  name: Zhuliang.name, 
  title: Zhuliang.title, 
  imageBase64: Zhuliang.imageBase64, 
  detailPath: '/our-team/zhuliang', 
  detailSummary: Zhuliang.detailSummary,
  detailFull: Zhuliang.detailFull
};
const monique: ITeamMember =  { 
  name: Monique.name, 
  title: Monique.title, 
  imageBase64: Monique.imageBase64, 
  detailPath: '/our-team/monique', 
  detailSummary: Monique.detailSummary,
  detailFull: Monique.detailFull
};

const appConfig: IAppContext = {
  header: navigations,
  footer: {
    navigations: navigations,
    services: [
      "Residential",
      "Commercial",
      "Construction",
      "Overseas & Expat",
      "Car Loan",
      "Personal Loan",
    ],
    contactPhone: "0459 193 122",
    contactEmail: "zhuliang.h@qafau.com.au",
    contactAddress: "Suite 1A 2-4 Whitehorse Rd Blackburn VIC 3130"
  },
  meetOurTeam: {
    left: zhuliang,
    right: monique
  },
  team: [ zhuliang, monique ]
}

const App = () => {
  return (
    <AppContext.Provider value={appConfig}>
    <div className="App">
      <Router>
        <Header />

        <Routes>
          <Route path="services" element={<Services />} />
          <Route path="our-team" element={<OurTeam />} />
          <Route path="our-team/zhuliang" element={<TeamMember member={zhuliang} />} />
          <Route path="our-team/monique" element={<TeamMember member={monique} />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>

        <Footer  />
      </Router>
    </div>
    </AppContext.Provider>
  );
}

export default App;


