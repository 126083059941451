import React, { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AppContext } from '../App';
import { ITeamMember } from '../types';


type Props = {}

const OurTeam = (props: Props) => {

  const context = React.useContext(AppContext);
  const team = context?.team as ITeamMember[];

  const renderTeamSection = team.map((item, index) => {
    return(
      <Row className='my-5' key={index}>
        <Col xs='4' sm='4' md='5' lg='5' xl='5'>
          <img src={item.imageBase64} alt={item.name} className='img-fluid img-thumbnail'></img>
        </Col>
        <Col xs='8' sm='8' md='7' lg='7' xl='7'>
          <h5 className="qaf-text-dark"><strong>{item.name}</strong></h5>

          <h5 className="qaf-text-light"><small>{item.title}</small></h5>

          <p className='qaf-text-dark pt-5'>{item.detailSummary} ...</p>

          <Link to={item.detailPath} style={{ textDecoration: "none" }}>
            <p className='qaf-text-light pt-5'>Read More</p>
          </Link>
        </Col>
      </Row>      
    )
  });


  return (
    <Fragment>
      <Container>
        <Row className='align-items-center justify-content-center pb-3'>
          <Col>
            <h1 className="mt-5 text-center">Our Team</h1>

            <hr className="page-title" style={{height: '2px'}} />

          </Col>
        </Row>
      </Container>

      <Container className='s-content-margin mb-4'>
        {renderTeamSection}
      </Container>
    </Fragment>
  )
}

export default OurTeam;