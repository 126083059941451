import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../assets/logo_light.png';
import {Container, Nav, Navbar} from 'react-bootstrap';
import { AppContext } from '../App';

type Props = {}

const Header = (props: Props) => {

    const context = React.useContext(AppContext);
    const headerConfig = context?.header;

    const navLinks = headerConfig?.map((item, index) => {
        return (
            <Nav.Item key={index}>
                <Nav.Link as={Link} to={item.path}>{item.name}</Nav.Link>
            </Nav.Item>
        );
      });

  return (
    <header>
      <Navbar bg="dark" expand='lg' fixed='top' variant='dark'>
        <Container>
            <Navbar.Brand>
                <Link to='/'>
                    <img src={logo} alt="QA Finance Logo" className="img-fluid" />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id='basic-navbar-nav' className='justify-content-end'>
                <Nav className='ml-auto'>
                    {navLinks}
                </Nav>
            </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header;