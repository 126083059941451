import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import aboutUsPhoto from '../assets/aboutussideimage.png'

type Props = {}

const AboutUsSection = (props: Props) => {
  return (
    <Container className="my-5 pt-5 about-section">

        <Row className="s-content-margin">
            <Col xs='12' sm='12' md='6' lg='6' xl='6'>
                <h2 className="section-title"><strong>About Us</strong></h2><hr />

                <h3 className="qaf-text-light py-4"><small>Dedicated professionals providing quality assured services.</small></h3>

                <div className="qaf-text-dark cms-plugin cms-plugin-1145">
                    <p>QA Finance specialises in mortgage broking.</p>

                    <p>We are dedicated to providing easy and quality-assured mortgage applying experience.</p>

                    <p>With extensive knowledge in mortgage lending, we believe our professional advices can provide customers a peaceful mind for their mortgage.</p>

                    <Link to='/about-us' style={{ textDecoration: "none" }}><Button variant='Secondary'>Discover More</Button></Link>
                </div>
            </Col>

            <Col xs='12' sm='12' md='6' lg='6' xl='6'>
                <div id="about-us-image">
                    <img src={aboutUsPhoto} alt="About Us" className="img-fluid" />
                </div>
            </Col>
        </Row>
    </Container>
  )
}

export default AboutUsSection