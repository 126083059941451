import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

type Props = {}

const HeroSection = (props: Props) => {
  return (
    <div className="hero-section-bg">
        <Container className="hero-section">
            <Row>
                <Col>
                    <h1 className="pt-3 qaf-text-white display-1 font-weight-bold qaf-text-bold">Welcome to QA Finance</h1>

                    <h3 className="hero-sub pb-4 qaf-text-white">Handling your mortgage and giving you peace of mind.</h3>
                </Col>
            </Row>
        </Container>
    </div>
  )


}

export default HeroSection