import * as React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo_light.png";
import { Container, Row, Col } from "react-bootstrap";
import { AppContext } from "../App";

type Props = {}

const Footer = (props: Props) => {
  const context = React.useContext(AppContext);
  const footerConfig = context?.footer;

  const servicesSection = footerConfig?.services.map((item, index) => {
    return <h6 key={index}>{item}</h6>;
  });

  const linksSection = footerConfig?.navigations.map((item, index) => {
    return (
      <h6 key={index}>
        <Link to={item.path} style={{ textDecoration: "none" }}>
          {item.name}
        </Link>
      </h6>
    );
  });

  const mailTo = `mailto:${footerConfig?.contactEmail}?subject=QA%20Finance%20Website%20Inquiry`
  const tel = `tel:${footerConfig?.contactPhone.replaceAll(' ', '')}`
  const addressGoogleMapLink = 'https://goo.gl/maps/DpXo34pM4RHz1nrM6';

  return (
    <footer className="pb-4 pt-5 mt-2 mt-auto bg-dark">
      <Container>
        <Row className="align-items-start justify-content-start">
          <Col xs="6" sm="6" md="3" lg="3" xl="3">
            <img src={logo} alt="QA Finance Logo" className="img-fluid" />
          </Col>

          <Col xs="6" sm="6" md="3" lg="3" xl="3">
            <div className="pb-3 footer-title">
              <h5>Quick Links</h5>
            </div>
            <div className="text-white">{linksSection}</div>
          </Col>

          <Col xs="6" sm="6" md="3" lg="3" xl="3">
            <div className="pb-3 footer-title">
              <h5>Services</h5>
            </div>
            <div className="text-white">{servicesSection}</div>
          </Col>

          <Col xs="6" sm="6" md="3" lg="3" xl="3">
            <div className="pb-3 footer-title">
              <h5>Contact Us</h5>
            </div>

            <div className="text-white">
              <h6>
                Phone:{" "}
                <a href={tel} style={{ textDecoration: "none" }}>
                  {footerConfig?.contactPhone}
                </a>
              </h6>

              {footerConfig?.contactEmail &&
                <h6>
                  Email:{" "}
                  <a
                    href={mailTo}
                    style={{ textDecoration: "none" }}
                  >
                    {footerConfig?.contactEmail}
                  </a>
                </h6>
              }


              <h6>
                  Address:{" "}
                  <a
                    href={addressGoogleMapLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    {footerConfig?.contactAddress}
                  </a>
              </h6>
            </div>
          </Col>
        </Row>
      </Container>

      <p className="m-0 mt-2 text-center text-white small">
        Copyright &copy; QA Finance Pty Ltd {new Date().getFullYear()}
      </p>
    </footer>
  );
}

export default Footer;