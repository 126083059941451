import React, { Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import MeetOurTeam from '../components/MeetOurTeam'
import darkLogo from '../assets/logo_dark.png'

type Props = {}

const AboutUs = (props: Props) => {
  return (
    <Fragment>
      <Container>
        <Row className='align-items-center justify-content-center'>
          <Col>
            <h1 className="mt-5 title_font text-center">About Us</h1>
            <hr className="page-title" style={{height: '2px'}} />
          </Col>
        </Row>

        <Row className='my-5 s-content-margin'>
          <Col xs='12' sm='12' md='6' lg='6' xl='6' className="px-3">
              <h2 className="qaf-text-dark"><strong>Our Company</strong></h2>

              <hr /><h3 className="qaf-text-light mt-2 mb-5"><small>Building a brighter financial future & life long support</small></h3>

              <div className="qaf-text-dark">
                  <p>QA Finance specialises in mortgage broking. </p>

                  <p>We are dedicated to providing easy and quality-assured mortgage applying experience.</p>

                  <p>With extensive knowledge in mortgage lending, we believe our professional advices can provide customers a peaceful mind for their mortgage.</p>
              </div>
          </Col>
          <Col xs='12' sm='12' md='6' lg='6' xl='6' className="d-none d-sm-none d-md-block px-3 py-3">
            <img src={darkLogo} alt='QA Finance Logo' className='img-fluid' />
          </Col>
        </Row>
        <Row className='s-content-margin'>
          <Col>
          
          <h2 className="qaf-text-dark"><strong>Why Choose Our Company</strong></h2>

          <hr />

          <h3 className="qaf-text-light mt-2 mb-5"><small>We promise sustainable future for you</small></h3>

          <div className="qaf-text-dark">
              <p>Mortgage applying process can be challenging. Many lenders on the market, and mortgage products come into variety with different names.</p>
              <p>People often get lost and cannot figure out what makes these differences that loan amount and interest rates vary from one lender to another, yet their financial position remains unchanged? That is why mortgage applying can never be a 'one fits for all' solution.</p>
              <p>At QA, we give tailored solution based on your unique financial position.</p>
          </div>

          <div className="mb-5"></div>
          </Col>

        </Row>

      </Container>

      <MeetOurTeam />
    </Fragment>

  )

}

export default AboutUs;