import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { ITeamMember } from '../types'

type Props = {
    member: ITeamMember
}

const TeamMember = (props: Props) => {
    const member = props.member;

    const renderDetails = member.detailFull.map((item, index) => {
        return (
            <p className='qaf-text-dark' key={index}>{item}</p>
        )
    });

  return (
    <Container>
        <Row className='align-items-center justify-content-center'>
            <Col>
                <h1 className="mt-5 member_name text-center">{member.name}</h1>
                <hr className="page-title" />
                <h3 className="qaf-text-light pt-3 pb-5 text-center">{member.title}</h3>
            </Col>
        </Row>
        <Row className='mb-4 py-5'>
            <Col xs='4' sm='4' md='5' lg='5' xl='5'>
                <img src={member.imageBase64} alt={member.name} className='img-fluid image-thumbnail' />
            </Col>

            <Col xs='8' sm='8' md='7' lg='7' xl='7'>
                {renderDetails}
            </Col>
        </Row>

    </Container>
  )
}

export default TeamMember