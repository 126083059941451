import React, { Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import MeetOurTeam from '../components/MeetOurTeam';
import ServiceSection from '../components/ServiceSection';

type Props = {}

const Services = (props: Props) => {
  return (
    <Fragment>
      <Container>
        <Row className='align-items-center justify-content-center'>
          <Col>
            <h1 className="mt-5 text-center" >Services</h1>

            <hr className="page-title" style={{height: '2px'}} />
            <h3 className="qaf-text-light pt-3 pb-5 text-center" >How we can help you</h3>
          </Col>
        </Row>
      </Container>
      <ServiceSection hasTitle={false} />      

      <MeetOurTeam />
    </Fragment>

  )
}

export default Services;