import React from "react";
import { Container, Row, Col } from "react-bootstrap";

type Props = {
  hasTitle: boolean;
};

const ServiceSection = (props: Props) => {
  return (
    <div className="bg-grey">
      <Container className="services-section py-5">
        {props.hasTitle ? (
          <Row className="s-content-margin">
            <Col>
              <h2 className="section-title">
                <strong>Services</strong>
              </h2>
              <hr />

              <h3 className="qaf-text-light py-4">How we can help you</h3>
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <h4 className="qaf-text-light pt-3">
              <strong>Residential</strong>
            </h4>

            <p className="qaf-text-dark">Home & Investment</p>
            <p className="qaf-text-dark pb-5">
              New Purchase, Refinance, Equity Release, Self Employed, Low Doc
            </p>
          </Col>

          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <h4 className="qaf-text-light pt-3">
              <strong>Commercial</strong>
            </h4>

            <p className="qaf-text-dark pb-5">
              Commercial Property, Warehouse, Business Loan
            </p>
          </Col>

          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <h4 className="qaf-text-light pt-3">
              <strong>Construction</strong>
            </h4>

            <p className="qaf-text-dark pb-5">House &amp; Land</p>
          </Col>

          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <h4 className="qaf-text-light pt-3">
              <strong>Overseas & Expat</strong>
            </h4>

            <p className="qaf-text-dark pb-3">
              Australians earning foreign income, Non-resident purchasing
              property in Australia
            </p>
          </Col>

          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <h4 className="qaf-text-light pt-3">
              <strong>Car Loan</strong>
            </h4>

            <p className="qaf-text-dark pb-3">Dealership, Second hand</p>
          </Col>

          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <h4 className="qaf-text-light pt-3">
              <strong>Personal Loan</strong>
            </h4>

            <p className="qaf-text-dark pb-3">Advice to weather the storm</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServiceSection;
