import React from 'react'
import { Col, Container, Row, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { AppContext } from '../App';
import { ITeamMember } from '../types';


type Props = {}

const MeetOurTeam = (props: Props) => {
    const context = React.useContext(AppContext);
    const teamLeft = context?.meetOurTeam.left as ITeamMember;
    const teamRight = context?.meetOurTeam.right as ITeamMember;

    return (
    <Container className="container my-5 ">

        <Row className="s-content-margin">
            <Col>
                <h2 className="section-title"><strong>Meet Our Team</strong></h2><hr />
            </Col>
        </Row>

        <Row className="align-items-center justify-content-center">

            {teamLeft &&
                <Col xs='12' sm='12' md='6' lg='6' xl='6'>
                    <div className="mx-5 my-5">

                        <Card>
                            <Link to={teamLeft.detailPath}>
                                <Card.Img variant='top' src={teamLeft.imageBase64} />
                            </Link>

                            <Card.Body>

                                <h6 className="qaf-text-dark">
                                    <Link to={teamLeft.detailPath} style={{ textDecoration: "none" }}>
                                        {teamLeft.name}
                                    </Link>
                                </h6>

                                <h6><small>{teamLeft.title}</small></h6>

                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            }           
            {teamRight &&
                <Col xs='12' sm='12' md='6' lg='6' xl='6'>
                    <div className="mx-5 my-5">

                        <Card>
                            <Link to={teamRight.detailPath}>
                                <Card.Img variant='top' src={teamRight.imageBase64} />
                            </Link>

                            <Card.Body>

                                <h6 className="qaf-text-dark">
                                    <Link to={teamRight.detailPath} style={{ textDecoration: "none" }}>
                                        {teamRight.name}
                                    </Link>
                                </h6>

                                <h6><small>{teamRight.title}</small></h6>

                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            }
        </Row>
    </Container>
  )
}

export default MeetOurTeam
